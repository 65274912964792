import React from 'react';
import { Box, Typography, Link, Button } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar'; // Ensure this component is compatible with Joy UI
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const PendingVerification = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
  };

  return (
    <Box sx={{ display: 'flex', paddingTop: '50px', backgroundColor: '#f0f8f6', minHeight: '100vh' }}>
      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Pending Verification
        </Typography>
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
          }}
        >
          Your application to join our platform is pending verification.
        </Typography>

        {/* Introductory Explanation */}
        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4, textAlign: 'center' }}
        >
          We are reviewing your details and will get back to you shortly. If you have any questions, please reach out to{' '}
          <Link
            href="mailto:support@algoinvest.io"
            sx={{ color: '#007BFF', textDecoration: 'none' }}
          >
            support@algoinvest.io
          </Link>
          .
        </Typography>

        {/* Log Out Button */}
        <Button 
          onClick={handleLogout}
          component={RouterLink}
          variant="solid"
          color="success"
          sx={{
            backgroundColor: '#40826D',
            '&:hover': { backgroundColor: '#357a68' },
            transition: 'background-color 0.3s ease',
            fontSize: '1rem',
            padding: '10px 20px',
            textTransform: 'none',
            borderRadius: '8px',
          }}
        >
          Log Out
        </Button>
      </Box>
    </Box>
  );
};

export default PendingVerification;
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Typography, Input, Button, Link, Alert } from '@mui/joy';
import TabBar from '../../components/tabs/TabBar';
import { isPendingVerification } from '../../utils/auth';
import { API_BASE_URL } from '../../constants';

const BLANK_LOGIN = {
  email: '',
  password: '',
};

const Login = () => {
  const [login, setLogin] = useState(BLANK_LOGIN);
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: false });
    setErrorMessage('');
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    const newErrors = {
      email: !login.email,
      password: !login.password,
    };
    setErrors(newErrors);

    if (newErrors.email || newErrors.password) {
      setErrorMessage('Please fill in all required fields.');
      return;
    }

    const loginData = {
      email: login.email,
      password: login.password,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      if (!response.ok) {
        throw new Error('Incorrect username or password. Please try again.');
      }

      const data = await response.json();
      const {
        refresh,
        access,
        user_id,
        firstname,
        lastname,
        is_email_verified,
        is_verified_by_algoinvest,
      } = data;

      localStorage.setItem('access_token', access);
      localStorage.setItem('refresh_token', refresh);
      localStorage.setItem('user_id', user_id);
      localStorage.setItem('is_email_verified', is_email_verified);
      localStorage.setItem('is_verified_by_algoinvest', is_verified_by_algoinvest);
      localStorage.setItem('user_name', `${firstname} ${lastname}`);

      if (isPendingVerification()) {
        navigate('/pending-verification');
      } else {
        navigate('/profile');
      }
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(error.message);
      setErrors({ email: true, password: true });
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#f0f8f6', display: 'flex', flexDirection: 'column' }}>
      <TabBar />
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: { xs: '2rem', md: '4rem' },
        }}
      >
        <Box
          component="form"
          onSubmit={handleLogin}
          sx={{
            padding: { xs: '2rem 1.5rem', md: '3rem 2.5rem' },
            maxWidth: 360,
            width: '100%',
            backgroundColor: 'inherit', // Match the surrounding background
            textAlign: 'center',
          }}
          noValidate
        >
          <Typography level="h4" align="center" gutterBottom sx={{ color: '#40826D', fontSize: '2.4rem', mb: '1rem', fontWeight: 'bold' }}>
            Welcome Back!
          </Typography>
          <Box sx={{ marginBottom: '1.5rem' }}>
            <Input
              name="email"
              type="email"
              placeholder="Email"
              value={login.email}
              onChange={handleChange}
              error={errors.email}
              required
              sx={{
                width: '100%',
                height: '3.5rem',
                padding: '1rem',
                fontSize: '1.3rem',
                borderRadius: '25px',
                borderColor: errors.email ? 'danger.600' : '#ddd',
                '&:focus': { borderColor: 'primary.600' },
              }}
            />
            {errors.email && (
              <Typography level="body3" color="danger.600" sx={{ mt: 0.5 }}>
                Email is required.
              </Typography>
            )}
          </Box>
          <Box sx={{ marginBottom: '1rem' }}>
            <Input
              name="password"
              type="password"
              placeholder="Password"
              value={login.password}
              onChange={handleChange}
              error={errors.password}
              required
              sx={{
                width: '100%',
                height: '3.5rem',
                padding: '1rem',
                fontSize: '1.3rem',
                borderRadius: '25px',
                borderColor: errors.password ? 'danger.600' : '#ddd',
                '&:focus': { borderColor: 'primary.600' },
              }}
            />
            {errors.password && (
              <Typography level="body3" color="danger.600" sx={{ mt: 0.5 }}>
                Password is required.
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
            <Link component={RouterLink} to="/forgot-password" variant="body2" color="#40826D" sx={{ fontSize: '0.875rem' }}>
              Forgot Password?
            </Link>
          </Box>
          {errorMessage && (
            <Alert color="danger" variant="soft" sx={{ marginBottom: '1rem' }}>
              {errorMessage}
            </Alert>
          )}
          <Button
            type="submit"
            variant="solid"
            color="success"
            fullWidth
            sx={{
              padding: '0.75rem',
              fontSize: '1.4rem',
              fontWeight: 'bold',
              backgroundColor: '#40826D',
              borderRadius: '30px',
              '&:hover': { backgroundColor: '#478778' },
            }}
          >
            Log In
          </Button>
          <Typography level="body2" align="center" sx={{ marginTop: '1.5rem', fontSize: '0.875rem' }}>
            Don't have an account?{' '}
            <Link component={RouterLink} to="/signup" variant="body2" color="#40826D">
              Sign Up
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;

import React from 'react';
import { Box, Typography, Sheet } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar';

const TermsAndConditions = () => {
  return (
    <Box sx={{ display: 'flex', paddingTop: '50px', backgroundColor: '#f0f8f6', minHeight: '100vh' }}>

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Terms and Conditions
        </Typography>
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
          }}
        >
          Please read these Terms and Conditions carefully before using our services.
        </Typography>

        {/* Introduction */}
        <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
          <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
            1. Agreement to Terms
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            These Terms and Conditions ("Terms") constitute a legally binding agreement between you and AlgoInvestor Fund
            ("AlgoInvest," "we," "our," or "us"), governing your access to and use of our website, mobile application, and
            investment advisory services (collectively, the "Services"). By accessing or using our Services, you agree to
            be bound by these Terms. If you disagree with any part of the Terms, you may not access the Services.
          </Typography>
        </Box>

        {/* Definitions */}
        <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
          <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
            2. Definitions
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 1 }}>
            <strong>2.1.</strong> "Client," "you," or "your" refers to the individual or entity that has registered for our Services.
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 1 }}>
            <strong>2.2.</strong> "Account" means the account created when you register for our Services.
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 1 }}>
            <strong>2.3.</strong> "ETFs" means Exchange-Traded Funds.
          </Typography>
        </Box>

        {/* Eligibility */}
        <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
          <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
            3. Eligibility
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            To use our Services, you must be at least 18 years old and capable of forming a binding contract. By using our
            Services, you represent and warrant that you meet these eligibility requirements.
          </Typography>
        </Box>

        {/* Registration and Account Security */}
        <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
          <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
            4. Registration and Account Security
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 1 }}>
            <strong>4.1.</strong> To access our Services, you must create an account by providing accurate, complete, and current information.
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 1 }}>
            <strong>4.2.</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            <strong>4.3.</strong> You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
          </Typography>
        </Box>

        {/* Prohibited Activities */}
        <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
          <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
            5. Prohibited Activities
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 1 }}>
            You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 1 }}>
            <strong>5.1.</strong> Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 1 }}>
            <strong>5.2.</strong> Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            <strong>5.3.</strong> Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein.
          </Typography>
        </Box>

        {/* Intellectual Property Rights */}
        <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
          <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
            6. Intellectual Property Rights
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            Unless otherwise indicated, the Site is our proprietary property, and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the "Content") and the trademarks, service marks, and logos contained therein (the "Marks") are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws.
          </Typography>
        </Box>

        {/* Governing Law and Jurisdiction */}
        <Box sx={{ textAlign: 'left', marginBottom: 4 }}>
          <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
            7. Governing Law and Jurisdiction
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            These Terms shall be governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law provisions. You agree to submit to the personal jurisdiction of the courts located in Wilmington, Delaware for the purpose of litigating all such claims or disputes.
          </Typography>
        </Box>

        {/* Agreement Statement */}
        <Box
          sx={{
            padding: '2rem',
            backgroundColor: '#f0f8f6',
            textAlign: 'center',
            borderRadius: 'md',
            marginTop: 4,
          }}
        >
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            By using our Services, you acknowledge that you have read and understood these Terms and agree to be bound by them.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
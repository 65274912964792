import React from 'react';
import { Box, Typography, Grid } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar';
import PropTypes from 'prop-types';

const Beta = () => {
  return (
    <Box sx={{ display: 'flex', backgroundColor: '#f0f8f6', minHeight: '100vh' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Beta: Broad Market Exposure Across Asset Classes
        </Typography>
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
            textAlign: 'center',
          }}
        >
          Learn how we capture general market movements by investing across equities, fixed income, commodities, and real estate.
        </Typography>
        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4 }}
        >
          Traditional beta often focuses on a single index, like the S&P 500, representing U.S. large-cap equities. At AlgoInvest, we take a broader approach. Our Beta encompasses multiple asset classes to ensure diversified market exposure.
        </Typography>

        {/* Explanation Section */}
        <Box sx={{ marginBottom: 4, textAlign: 'left' }}>
          <Typography
            sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 3 }}
          >
            How We Use Beta
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            Our Beta strategy involves diversified market exposure across multiple asset classes, including equities, fixed income, commodities, and real estate. We allocate dynamically between these sub-betas based on market conditions, optimizing for risk-adjusted returns.
          </Typography>
          <Typography
            sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginTop: 2 }}
          >
            By constantly monitoring all of these themes and decomposing our assets into meaningful subcategories (such as global broad for equities or large-cap growth for size), we can deeply understand each asset and its relationships within the portfolio. This helps our models stay informed and up-to-date with real-time market dynamics.
          </Typography>
        </Box>

        {/* Beta Sections */}
        <Box>
          <BetaSections />
        </Box>
      </Box>
    </Box>
  );
};

export default Beta;

// BetaSections Component
const BetaSections = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    {/* Grid of Beta Cards */}
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <BetaCard title="Expanding Beta Beyond Equities">
          <Typography sx={{ color: '#333' }}>
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Equities:</strong> Stocks from various regions and sectors.</li>
              <li><strong>Fixed Income:</strong> Government and corporate bonds.</li>
              <li><strong>Commodities:</strong> Physical goods like gold, oil, and agricultural products.</li>
              <li><strong>Real Estate:</strong> Investments in property markets.</li>
            </ul>
          </Typography>
        </BetaCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <BetaCard title="The Hierarchical Beta Tree">
          <Typography sx={{ color: '#333' }}>
            We utilize a hierarchical tree structure to break down asset classes into sub-categories and allocate between sub-betas for optimal exposure. This approach helps monitor and manage risk effectively within all the markets we're active in.
            Our quants developed sophisticated methods to obtain prior and posterior views on the market to help us understand what bets to take and when to take them.
          </Typography>
        </BetaCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <BetaCard title="Breaking Betas into Sub-Betas">
          <Typography sx={{ color: '#333' }}>
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Global Broad:</strong> Investing in equities all around the world (<em>ACWI</em>, <em>VT</em>, <em>CWI</em>).</li>
              <li><strong>Developed Markets:</strong> Focusing on North America and Europe (<em>VXUS</em>, <em>IEFA</em>).</li>
              <li><strong>Emerging Markets:</strong> Growth in Asia and Latin America (<em>VWO</em>, <em>EMQQ</em>, <em>ARGT</em>).</li>
              <li><strong>Residential Real Estate:</strong> Property investments (<em>VNQ</em>, <em>REZ</em>, <em>XHB</em>).</li>
            </ul>
          </Typography>
        </BetaCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <BetaCard title="Dynamic Allocation Between Sub-Betas">
          <Typography sx={{ color: '#333' }}>
            Based on market conditions and our risk management framework, we dynamically adjust allocations between sub-betas. Our advanced analysis continuously monitors market themes and asset relationships to keep your portfolio optimized for performance, ensuring that it remains agile and responsive to market dynamics.
          </Typography>
        </BetaCard>
      </Grid>
    </Grid>
  </Box>
);

// BetaCard Component
const BetaCard = ({ title, children }) => (
  <Box sx={{ padding: '1.5rem', display: 'flex', flexDirection: 'column' }}>
    <Typography
      sx={{
        fontSize: '1.25rem',
        fontWeight: '600',
        color: '#40826D',
        marginBottom: '1rem',
      }}
    >
      {title}
    </Typography>
    <Typography sx={{ flexGrow: 1, fontSize: '1rem', lineHeight: 1.8, color: '#333' }}>
      {children}
    </Typography>
  </Box>
);

BetaCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
import React from 'react';
import { Box, Typography, Grid } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar';
import PropTypes from 'prop-types';

const TaxEfficiencies = () => {
  return (
    <Box sx={{ display: 'flex', backgroundColor: '#f0f8f6', minHeight: '100vh' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Tax-Efficient Investing
        </Typography>
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
            textAlign: 'center',
          }}
        >
          We can help maximize your returns by optimizing your tax strategies.
        </Typography>
        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4 }}
        >
          Learn how techniques like tax-loss harvesting can help offset gains and reduce your taxable income. Additionally, leveraging tax-advantaged accounts such as IRAs and 401(k)s allows you to grow wealth in a more tax-efficient manner, providing long-term benefits like deferred or exempt taxation, ultimately enhancing your overall investment performance.
        </Typography>

        {/* Explanation Section */}
        <Box sx={{ marginBottom: 4, textAlign: 'left' }}>
          <Typography
            sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 3 }}
          >
            How We Optimize for Taxes
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            By using tax-advantaged accounts, tax-loss harvesting, and tailored asset allocation strategies, we ensure that your portfolio grows efficiently while minimizing tax liabilities.
          </Typography>
        </Box>

        {/* Tax Sections */}
        <Box>
          <TaxAdvantagedAccounts />
          <BrokerageAccounts />
          <TaxEfficientPortfolioManagement />
          <TaxLossHarvesting />
        </Box>
      </Box>
    </Box>
  );
};

export default TaxEfficiencies;

// TaxAdvantagedAccounts Component
const TaxAdvantagedAccounts = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
      Tax-Advantaged Accounts
    </Typography>
    <Typography sx={{ lineHeight: 1.8, fontSize: '1.15rem', color: '#333', marginBottom: 4 }}>
      Tax-advantaged accounts offer benefits like tax deductions, deferred taxes, or tax-free growth, helping you grow your wealth more efficiently. We can adjust your portfolio allocations within these accounts to maximize tax benefits.
    </Typography>
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} md={4}>
        <TaxCard title="Pre-Tax 401(k)">
          Contributions reduce your taxable income today, and taxes are paid upon withdrawal in retirement.
        </TaxCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TaxCard title="After-Tax 401(k)">
          Contributions are made after taxes, but earnings grow tax-deferred. Can be rolled over to a Roth IRA for potential tax-free withdrawals.
        </TaxCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TaxCard title="Roth IRA">
          After-tax contributions with qualified tax-free withdrawals in retirement.
        </TaxCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TaxCard title="Traditional IRA">
          Contributions may be tax-deductible, with tax-deferred growth until withdrawal.
        </TaxCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TaxCard title="Health Savings Account (HSA)">
          Triple tax advantages: tax-deductible contributions, tax-free growth, and tax-free withdrawals for qualified medical expenses.
        </TaxCard>
      </Grid>
    </Grid>
  </Box>
);

// BrokerageAccounts Component
const BrokerageAccounts = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
      Brokerage Accounts
    </Typography>
    <Typography sx={{ lineHeight: 1.8, fontSize: '1.15rem', color: '#333', marginBottom: 4 }}>
      While these accounts don't offer tax advantages, they provide flexibility with fewer restrictions on contributions and withdrawals. We strategically manage investments in these accounts to minimize tax liabilities through techniques like tax-loss harvesting.
    </Typography>
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} md={6}>
        <TaxCard title="Individual Account">
          Owned by one person, allowing full control over investment decisions. Capital gains and dividends are taxed annually.
        </TaxCard>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TaxCard title="Joint Account">
          Shared ownership between two or more individuals, commonly used by spouses. Offers combined investment efforts and shared tax responsibilities.
        </TaxCard>
      </Grid>
    </Grid>
  </Box>
);

// TaxEfficientPortfolioManagement Component
const TaxEfficientPortfolioManagement = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
      Tailoring Your Portfolio Based on Account Types
    </Typography>
    <Typography sx={{ lineHeight: 1.8, fontSize: '1.15rem', color: '#333', marginBottom: 4 }}>
      Different account types have unique tax rules and benefits. We adjust your portfolio allocations accordingly to maximize tax efficiency:
    </Typography>
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} md={4}>
        <TaxCard title="Tax-Advantaged Accounts">
          We prioritize placing income-generating assets, like bonds or high-dividend stocks, in these accounts to take advantage of tax-deferred or tax-free growth.
        </TaxCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TaxCard title="Taxable Brokerage Accounts">
          We focus on tax-efficient investments, such as index funds and ETFs with lower turnover, to minimize capital gains taxes.
        </TaxCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TaxCard title="Asset Location Strategy">
          By strategically placing assets in the appropriate accounts, we reduce your overall tax burden and enhance after-tax returns.
        </TaxCard>
      </Grid>
    </Grid>
  </Box>
);

// TaxLossHarvesting Component
const TaxLossHarvesting = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', color: '#40826D', marginBottom: 2 }}>
      Tax-Loss Harvesting Strategy
    </Typography>
    <Typography sx={{ lineHeight: 1.8, fontSize: '1.15rem', color: '#333', marginBottom: 4 }}>
      Our advanced algorithms continuously monitor your portfolio to identify and execute tax-loss harvesting opportunities. By strategically selling underperforming assets, we can offset gains in other investments, reducing your overall tax liability.
    </Typography>
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} md={4}>
        <TaxCard title="What is Tax-Loss Harvesting?">
          This strategy involves selling investments at a loss to offset capital gains taxes from profitable investments, helping reduce your overall tax bill.
        </TaxCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TaxCard title="How We Use Tax-Loss Harvesting">
          We automatically detect opportunities to sell underperforming assets and replace them with similar investments to maintain your portfolio's asset allocation and expected returns while capturing the tax benefits.
        </TaxCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
      <TaxCard title="Benefits for You">
          Implementing tax-loss harvesting can significantly lower your tax liabilities, enhance your after-tax returns, and help your portfolio grow more efficiently over time.
        </TaxCard>
      </Grid>
    </Grid>
  </Box>
);

// TaxCard Component
const TaxCard = ({ title, children }) => (
  <Box
    sx={{
      backgroundColor: '#f0f8f6',
      padding: '1.5rem',
      borderRadius: 'md',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Typography
      sx={{
        fontSize: '1.25rem',
        fontWeight: '600',
        color: '#40826D',
        marginBottom: '1rem',
      }}
    >
      {title}
    </Typography>
    <Typography sx={{ flexGrow: 1, fontSize: '1rem', lineHeight: 1.8, color: '#333' }}>
      {children}
    </Typography>
  </Box>
);

TaxCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
import React from 'react';
import { Box, Typography, Grid } from '@mui/joy';
import beginnerFriendlyIcon from '../../assets/images/beginner-friendly-icon.png';
import maximizeGrowthIcon from '../../assets/images/maximize-growth-icon.png';
import riskBudgetingIcon from '../../assets/images/risk-budgeting-icon.png';

const Approach = () => {
  const approachItems = [
    {
      label: 'ALPHA',
      img: riskBudgetingIcon,
      title: 'Market Insights',
      description:
        'Our Alpha product overlays an expert view on top of our Beta strategies, aiming to beat the market through advanced statistical, machine learning, and AI algorithms. By identifying alpha factors specific to assets we trade, we provide additional value by enhancing returns beyond standard market exposures, delivering superior performance.',
    },
    {
      label: 'BETA',
      img: maximizeGrowthIcon,
      title: 'Diverse Exposure',
      description:
        'Our Beta product provides broad exposure to all major market risks through ETFs, including equities (US, Europe, Asia, etc.), fixed-income (government, corporate, etc.), commodities (energy, food, etc.), and real estate (residential, commercial, US, international). This diversified approach helps spread risk across various asset classes, allowing your money to grow while avoiding severe drawdowns caused by a single asset class.',
    },
    {
      label: 'RHO',
      img: beginnerFriendlyIcon,
      title: 'Risk-Free',
      description:
        'Our Rho product allows you to invest with us and experience a truly risk-free opportunity. This kind of product is commonly referred to as a high yield savings account, effectively offering a “free money” proposition with zero risk at a predetermined rate like 4% annually. In recent times, accounts like these have reached rates high as 5.5%!',
    },
  ];

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        bgcolor: '#f9f9f9',
        py: { xs: 6, md: 10 },
        textAlign: 'center',
        px: { xs: 2, md: 4 },
      }}
    >
      <Typography
        level="h4"
        sx={{ fontSize: { xs: '2.5rem', md: '3rem' }, color: '#2f3b52', mb: 8, fontWeight: 'bold' }}
      >
        Our Approach to Investing
      </Typography>
      <Grid container spacing={4} sx={{ maxWidth: '1200px', margin: '0 auto' }}>
        {approachItems.map((item, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Box
              sx={{
                bgcolor: '#ffffff',
                borderRadius: '100px',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                p: { xs: 3, md: 4 },
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
                },
              }}
            >
              <Typography
                level="subtitle1"
                sx={{
                  fontSize: '1.8rem',
                  fontWeight: 'bold',
                  color: '#40826D',
                  mb: 2,
                }}
              >
                {item.label}
              </Typography>
              <img
                src={item.img}
                alt={item.title}
                style={{ color: '#40826D', width: '150px', height: '120px', marginBottom: '20px' }}
              />
              <Typography level="h5" sx={{ fontSize: '1.8rem', color: '#2f3b52', mb: 2, fontWeight: '600' }}>
                {item.title}
              </Typography>
              <Typography
                level="body1"
                sx={{ color: '#5a6b7d', fontSize: '1.1rem', lineHeight: 1.6, mb: 2, px: { xs: 1, md: 2 } }}
              >
                {item.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Approach;
// Performance.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  FormControl,
  Select,
  Option,
} from '@mui/joy';
import { Tabs, Tab } from '@mui/material';
import { useParams } from 'react-router-dom';
import PerformanceChart from '../../components/charts/PerformanceChart';
import CorrelationHeatmap from '../../components/charts/CorrelationHeatmap';
import InternalTabBar from '../../components/tabs/InternalTabBar';
import MetricsList from '../../components/charts/MetricsList';
import {
  granularityOptions,
  generateInvestmentData,
  generateCorrelationMatrix,
  getRandomStatistics,
  generateRiskFactorReturns,
  generateIdiosyncraticReturns,
  generateAlphaFactorReturns,
  generatePortfolioWeights,
} from '../../data/assetClassDataGenerators';
import {
  assetClasses,
  equitiesData,
  fixedIncomeData,
  realEstateData,
  commoditiesData,
  subgroupDisplayNames,
} from '../../data/assetClassData';

const rhoAsset = 'Rho'; // Define Rho as an asset if needed

// Map asset class names to their corresponding data objects
const assetClassMap = {
  Equities: equitiesData,
  'Fixed Income': fixedIncomeData,
  'Real Estate': realEstateData,
  Commodities: commoditiesData,
};

// Function to get descriptions for each option
const getOptionDescription = (option) => {
  switch (option) {
    case 'Asset':
      return 'Asset returns represent the performance of the Core Fund or selected asset class and its components over time.';
    case 'Factor':
      return 'Risk factor returns decompose the Core Fund or selected asset class into underlying factors that drive performance.';
    case 'Specific':
      return 'Idiosyncratic returns capture the specific performance not explained by common factors.';
    case 'Alpha':
      return 'Alpha factors represent strategies aiming to outperform by exploiting inefficiencies.';
    case 'Portfolio':
      return 'Portfolio weights show the allocation to each component over time.';
    default:
      return '';
  }
};

const Performance = () => {
  const { assetClassParam } = useParams(); // Get assetClassParam from URL
  const [granularity, setGranularity] = useState('10Y');
  const [selectedOption, setSelectedOption] = useState('Asset');
  const [selectedAssetClass, setSelectedAssetClass] = useState('Core');
  const [chartData, setChartData] = useState([]);
  const [correlationData, setCorrelationData] = useState([]);
  const [metrics, setMetrics] = useState([]);

  // For Asset Classes
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedSubgroup, setSelectedSubgroup] = useState(null);

  // Update selectedAssetClass based on URL parameter
  useEffect(() => {
    if (assetClassParam) {
      const normalizedParam = assetClassParam
        .replace(/-/g, ' ')
        .replace(/\b\w/g, (l) => l.toUpperCase());
      if (normalizedParam === 'Core') {
        setSelectedAssetClass('Core');
      } else if (assetClasses.includes(normalizedParam)) {
        setSelectedAssetClass(normalizedParam);
      }
    } else {
      setSelectedAssetClass('Core');
    }
    setSelectedGroup(null);
    setSelectedSubgroup(null);
  }, [assetClassParam]);

  useEffect(() => {
    let updatedChartData = [];
    let updatedCorrelationData = [];
    let updatedMetrics = [];

    let assets = [];
    let n_assets = 0;

    if (selectedAssetClass === 'Core') {
      // Core Fund logic
      assets = ['Core Fund', ...assetClasses];
      n_assets = assets.length;

      switch (selectedOption) {
        case 'Asset':
          // Include Core Fund and asset classes
          const assetGroups = ['Core Fund', ...assetClasses];

          updatedChartData = assetGroups.map((asset) => ({
            label: asset,
            data: generateInvestmentData(granularity, 100000, asset),
          }));
          updatedCorrelationData = generateCorrelationMatrix(assetGroups);
          updatedMetrics = assetGroups.map((asset) => ({
            name: asset,
            ...getRandomStatistics(),
          }));
          break;

        case 'Factor':
          const n_factors = Math.floor(n_assets / 2) || 1;
          updatedChartData = generateRiskFactorReturns(n_factors, granularity);
          updatedCorrelationData = generateCorrelationMatrix(
            updatedChartData.map((d) => d.label)
          );
          updatedMetrics = updatedChartData.map((factor) => ({
            name: factor.label,
            ...getRandomStatistics(),
          }));
          break;

        case 'Specific':
          // Include specific returns for Core Fund and asset classes
          const specificAssets = ['Core Fund', ...assetClasses];
          updatedChartData = specificAssets.map((asset) => ({
            label: asset,
            data: generateIdiosyncraticReturns(asset, granularity),
          }));
          updatedCorrelationData = generateCorrelationMatrix(specificAssets);
          updatedMetrics = specificAssets.map((asset) => ({
            name: asset,
            ...getRandomStatistics(),
          }));
          break;

        case 'Alpha':
          const n_alpha_factors = Math.floor(n_assets / 2) || 1;
          updatedChartData = generateAlphaFactorReturns(n_alpha_factors, granularity);
          updatedCorrelationData = generateCorrelationMatrix(
            updatedChartData.map((d) => d.label)
          );
          updatedMetrics = updatedChartData.map((factor) => ({
            name: factor.label,
            ...getRandomStatistics(),
          }));
          break;

        case 'Portfolio':
          const portfolioAssets = [...assetClasses, rhoAsset];
          updatedChartData = generatePortfolioWeights(portfolioAssets, granularity);
          updatedMetrics = portfolioAssets.map((asset) => ({
            name: asset,
            ...getRandomStatistics(),
          }));
          updatedCorrelationData = [];
          break;

        default:
          break;
      }
    } else {
      // Asset class selected
      const selectedAssetClassData = assetClassMap[selectedAssetClass];

      if (selectedAssetClassData) {
        // Use subclasses (themes) of the selected asset class
        const themes = selectedAssetClassData.subclasses || [];
        assets = themes;
        n_assets = assets.length;

        // Determine the assets based on selectedGroup and selectedSubgroup
        if (selectedSubgroup) {
          const subgroup =
            selectedAssetClassData.groups[selectedGroup].subgroups[selectedSubgroup];
          assets = subgroup.etfs.map((etf) => etf.symbol);
        } else if (selectedGroup) {
          const subgroups = selectedAssetClassData.groups[selectedGroup].subgroups;
          assets = Object.keys(subgroups).map(
            (subgroupName) => subgroupDisplayNames[subgroupName] || subgroupName
          );
        } else {
          assets = Object.keys(selectedAssetClassData.groups);
        }

        n_assets = assets.length;

        switch (selectedOption) {
          case 'Asset':
            updatedChartData = assets.map((asset) => ({
              label: asset,
              data: generateInvestmentData(granularity, 100000, asset),
            }));
            updatedCorrelationData = generateCorrelationMatrix(assets);
            updatedMetrics = assets.map((asset) => ({
              name: asset,
              ...getRandomStatistics(),
            }));
            break;

          case 'Factor':
            const n_factors = Math.floor(n_assets / 2) || 1;
            updatedChartData = generateRiskFactorReturns(n_factors, granularity);
            updatedCorrelationData = generateCorrelationMatrix(
              updatedChartData.map((d) => d.label)
            );
            updatedMetrics = updatedChartData.map((factor) => ({
              name: factor.label,
              ...getRandomStatistics(),
            }));
            break;

          case 'Specific':
            // Include specific returns for all assets in the selected asset class
            updatedChartData = assets.map((asset) => ({
              label: asset,
              data: generateIdiosyncraticReturns(asset, granularity),
            }));
            updatedCorrelationData = generateCorrelationMatrix(assets);
            updatedMetrics = assets.map((asset) => ({
              name: asset,
              ...getRandomStatistics(),
            }));
            break;

          case 'Alpha':
            const n_alpha_factors = Math.floor(n_assets / 2) || 1;
            updatedChartData = generateAlphaFactorReturns(n_alpha_factors, granularity);
            updatedCorrelationData = generateCorrelationMatrix(
              updatedChartData.map((d) => d.label)
            );
            updatedMetrics = updatedChartData.map((factor) => ({
              name: factor.label,
              ...getRandomStatistics(),
            }));
            break;

          case 'Portfolio':
            const portfolioThemes = [...assets, rhoAsset];
            updatedChartData = generatePortfolioWeights(portfolioThemes, granularity);
            updatedMetrics = portfolioThemes.map((asset) => ({
              name: asset,
              ...getRandomStatistics(),
            }));
            updatedCorrelationData = [];
            break;

          default:
            break;
        }
      }
    }

    setChartData(updatedChartData);
    setCorrelationData(updatedCorrelationData);
    setMetrics(updatedMetrics);
  }, [
    selectedOption,
    granularity,
    selectedAssetClass,
    selectedGroup,
    selectedSubgroup,
  ]);

  const handleAssetClassChange = (event, newValue) => {
    setSelectedAssetClass(newValue);
    setSelectedGroup(null);
    setSelectedSubgroup(null);
  };

  const handleGroupClick = (group) => {
    setSelectedGroup(selectedGroup === group ? null : group);
    setSelectedSubgroup(null);
  };

  const handleSubgroupClick = (subgroup) => {
    setSelectedSubgroup(selectedSubgroup === subgroup ? null : subgroup);
  };

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#f0f8f6' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '89%',
          margin: '0 auto',
          marginLeft: '11%',
          flexGrow: 1,
        //   padding: { xs: 2, md: 4 },
          marginTop: '85px', 
          textAlign: 'center',
        }}
      >
        {/* Asset Class Tabs */}
        <Tabs
          value={selectedAssetClass}
          onChange={handleAssetClassChange}
          centered
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab value="Core" label="Core" />
          {assetClasses.map((assetClass) => (
            <Tab key={assetClass} value={assetClass} label={assetClass} />
          ))}
        </Tabs>

        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            marginTop: '65px',
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          {selectedAssetClass === 'Core'
            ? 'Core Fund Performance'
            : `${selectedAssetClass} Performance`}
        </Typography>

        {/* Description */}
        {selectedAssetClass === 'Core' ? (
          <>
            <Typography
              sx={{
                marginBottom: 4,
                fontSize: '1.5rem',
                color: '#478778',
              }}
            >
              Track the growth and performance of your Core Fund over various time periods.
            </Typography>
            <Typography
              sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4 }}
            >
              The Core Fund offers diversified exposure to Beta and Alpha strategies across equities, fixed income, real estate, and commodities, providing a comprehensive approach to capturing market returns and seeking outperformance.
            </Typography>
          </>
        ) : (
          <>
            <Typography
              sx={{
                marginBottom: 4,
                fontSize: '1.5rem',
                color: '#478778',
              }}
            >
              {assetClassMap[selectedAssetClass].description}
            </Typography>
            <Typography
              sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4 }}
            >
              {assetClassMap[selectedAssetClass].longDescription}
            </Typography>
          </>
        )}

        {/* Time Range Selector */}
        <Box sx={{ display: 'flex', justifyContent: 'center', margin: 4 }}>
          <FormControl sx={{ minWidth: 250 }} size="lg">
            <Select
              placeholder="Select Time Period"
              value={granularity}
              onChange={(e, newValue) => setGranularity(newValue)}
              sx={{
                fontSize: '1.15rem',
                color: '#333',
                backgroundColor: '#ffffff',
                padding: '8px 20px',
                fontWeight: '500',
                borderRadius: '30px',
                border: '1px solid #478778',
                '&:hover': {
                  backgroundColor: '#e6f7f2',
                },
                '& .Mui-selected': {
                  color: '#40826D',
                },
              }}
            >
              {granularityOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Option Selector */}
        <Box sx={{ marginTop: 4 }}>
          <Tabs
            value={selectedOption}
            onChange={(e, newValue) => setSelectedOption(newValue)}
            centered
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab value="Asset" label="Asset" />
            <Tab value="Factor" label="Factor" />
            <Tab value="Specific" label="Specific" />
            <Tab value="Alpha" label="Alpha" />
            <Tab value="Portfolio" label="Portfolio" />
          </Tabs>
        </Box>

        {/* Option Description */}
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1">
            {getOptionDescription(selectedOption)}
          </Typography>
        </Box>

        {/* Group and Subgroup Selection for Asset Classes */}
        {selectedAssetClass !== 'Core' && (
          <>
            {/* Group Selection */}
            <Typography
              sx={{ marginTop: 4, marginBottom: 2, fontSize: '1.5rem', fontWeight: 'bold' }}
            >
              Select a Beta Theme
            </Typography>
            {assetClassMap[selectedAssetClass].subclasses &&
              assetClassMap[selectedAssetClass].subclasses.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: 2,
                    marginBottom: 2,
                  }}
                >
                  {assetClassMap[selectedAssetClass].subclasses.map((group) => (
                    <Button
                      key={group}
                      variant={selectedGroup === group ? 'solid' : 'outlined'}
                      onClick={() => handleGroupClick(group)}
                      sx={{
                        margin: 1,
                        borderRadius: '30px',
                        padding: '8px 20px',
                        fontWeight: 'bold',
                        fontSize: '1.15rem',
                        flexBasis: '30%',
                        minWidth: '180px',
                        backgroundColor: selectedGroup === group ? '#40826D' : '#ffffff',
                        color: selectedGroup === group ? '#ffffff' : '#40826D',
                        '&:hover': {
                          backgroundColor: selectedGroup === group ? '#40826D' : '#f0f0f0',
                        },
                      }}
                    >
                      {group}
                    </Button>
                  ))}
                </Box>
              )}

            {/* Subgroup Selection */}
            {selectedGroup && (
              <>
                <Typography
                  sx={{ marginBottom: 4, fontSize: '1.5rem', fontWeight: 'bold' }}
                >
                  Select a Beta Sub-Theme
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: 2,
                    marginBottom: 4,
                    maxWidth: '80%',
                    margin: '0 auto',
                  }}
                >
                  {Object.keys(
                    assetClassMap[selectedAssetClass].groups[selectedGroup].subgroups
                  ).map((subgroup) => (
                    <Button
                      key={subgroup}
                      variant={selectedSubgroup === subgroup ? 'solid' : 'outlined'}
                      onClick={() => handleSubgroupClick(subgroup)}
                      sx={{
                        flexBasis: '30%',
                        minWidth: '150px',
                        margin: '10px',
                        borderRadius: '30px',
                        padding: '8px 20px',
                        fontWeight: 'bold',
                        fontSize: '1.15rem',
                        backgroundColor:
                          selectedSubgroup === subgroup ? '#4169E1' : '#ffffff',
                        color: selectedSubgroup === subgroup ? '#ffffff' : '#4169E1',
                        '&:hover': {
                          backgroundColor:
                            selectedSubgroup === subgroup ? '#4169E1' : '#f0f0f0',
                        },
                      }}
                    >
                      {subgroupDisplayNames[subgroup] ||
                        subgroup.replace(/_/g, ' ')}
                    </Button>
                  ))}
                </Box>
              </>
            )}
          </>
        )}

        {/* Performance Chart */}
        <Box sx={{ height: { xs: 400, md: 600 }, marginBottom: 4, marginTop: 4 }}>
          <Typography
            sx={{ marginBottom: 4, fontSize: '1.5rem', fontWeight: 'bold', color: '#000000' }}
          >
            Performance
          </Typography>
          <PerformanceChart
            data={chartData}
            granularity={granularity}
            yAxisLabel={
              selectedOption === 'Portfolio'
                ? 'Portfolio Weight'
                : selectedOption === 'Specific'
                ? 'Return'
                : 'Investment Value (USD)'
            }
            tooltipFormat={(value) =>
              selectedOption === 'Portfolio'
                ? `${(value * 100).toFixed(2)}%`
                : selectedOption === 'Specific'
                ? `${(value * 100).toFixed(2)}%`
                : `$${value.toFixed(2)}`
            }
          />
        </Box>

        {/* Correlation Heatmap */}
        {selectedOption !== 'Portfolio' && correlationData.length > 1 && (
          <Box sx={{ marginBottom: 4 }}>
            <Typography
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: '#40826D',
                marginBottom: 3,
              }}
            >
              Correlation Heatmap
            </Typography>
            <CorrelationHeatmap correlations={correlationData} />
          </Box>
        )}

        {/* Metrics List */}
        <MetricsList metrics={metrics} />
      </Box>
    </Box>
  );
};

export default Performance;

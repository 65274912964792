
import React from 'react';
import { Box, Typography, FormControl, Select, Option, Button } from '@mui/joy';
import { Link as RouterLink } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';


const ProfileHeader = ({ userName, selectedAccount, accounts, onAccountChange }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#f0f8f6',
        padding: '1.5rem',
        marginBottom: '2rem',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#2f3b52',
        // Removed border and box shadow
        boxShadow: 'none',
        borderRadius: 'none',
      }}
    >
      <Typography
        level="h4"
        component="h1"
        sx={{
          marginBottom: { xs: '1rem', md: '0' },
          fontSize: '2.5rem',
          fontWeight: 'bold',
          color: '#40826D',
        }}
      >
        Welcome, {userName}
      </Typography>
                      {/* Settings Button */}
          <Box sx={{ textAlign: 'right'}}>
              <Button
                variant="outlined"
                color="neutral"
                component={RouterLink}
                to="/settings"
                startDecorator={<SettingsIcon />}
                sx={{
                  fontSize: '1.1rem',
                  padding: '10px 30px',
                  borderRadius: '30px',
                  borderWidth: '1px',
                  borderInlineColor: 'f0f8f6',
                  fontWeight: '600',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#e6f7f2',
                  },
                }}
              >
                Go to Settings
              </Button>
            </Box>
    </Box>
  );
};

export default ProfileHeader;

import { expectedReturns, expectedVolatilities } from './assetClassData';

export const generatePortfolioData = (granularity, initialValue, label) => {
    // Default expectedReturn and expectedVolatility
    let expectedReturn = 0.08; // Default for Core Fund
    let expectedVolatility = 0.12; // Default volatility
  
    // Check if label is in expectedReturns and expectedVolatilities
    if (expectedReturns[label]) {
      expectedReturn = expectedReturns[label];
      expectedVolatility = expectedVolatilities[label];
    } else {
      // For ETFs or subgroups, assign random expected returns and volatilities
      expectedReturn = Math.random() * 0.1 + 0.05; // Between 5% and 15%
      expectedVolatility = Math.random() * 0.1 + 0.1; // Between 10% and 20%
    }
  
    const now = new Date();
    let startDate;
    let interval;
    let points;
    let pointsPerYear;
  
    switch (granularity) {
      case '10Y':
        startDate = new Date(now.getFullYear() - 10, now.getMonth(), now.getDate());
        interval = 'month';
        points = 121;
        pointsPerYear = 12;
        break;
      case '5Y':
        startDate = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate());
        interval = 'month';
        points = 61;
        pointsPerYear = 12;
        break;
      case '1Y':
        startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        interval = 'week';
        points = 53;
        pointsPerYear = 52;
        break;
      case '6M':
        startDate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
        interval = 'week';
        points = 27;
        pointsPerYear = 52;
        break;
      case '1M':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
        interval = 'day';
        points = 31;
        pointsPerYear = 365;
        break;
      case '1W':
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
        interval = 'hour';
        points = 169;
        pointsPerYear = 8760;
        break;
      case '1D':
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
        interval = 'hour';
        points = 25;
        pointsPerYear = 8760;
        break;
      default:
        startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        interval = 'week';
        points = 53;
        pointsPerYear = 52;
    }
  
    const data = [];
    let currentDate = new Date(startDate);
    let investmentValue = initialValue;
  
    for (let i = 0; i < points; i++) {
      const randomReturn = (expectedReturn / pointsPerYear) + (expectedVolatility / Math.sqrt(pointsPerYear)) * (Math.random() - 0.5);
      investmentValue *= Math.exp(randomReturn);
  
      data.push({ x: new Date(currentDate), y: parseFloat(investmentValue.toFixed(2)) });
  
      switch (interval) {
        case 'month':
          currentDate.setMonth(currentDate.getMonth() + 1);
          break;
        case 'week':
          currentDate.setDate(currentDate.getDate() + 7);
          break;
        case 'day':
          currentDate.setDate(currentDate.getDate() + 1);
          break;
        case 'hour':
          currentDate.setHours(currentDate.getHours() + 1);
          break;
      }
    }
  
    return data;
  };


  export const generateInvestmentData = (granularity, initialValue, label) => {
    let expectedReturn = 0.08; // default value
    let expectedVolatility = 0.12; // default value
  
    if (expectedReturns[label]) {
      expectedReturn = expectedReturns[label];
      expectedVolatility = expectedVolatilities[label];
    } else {
      // For ETFs or subgroups, assign random expected returns and volatilities
      expectedReturn = Math.random() * 0.1 + 0.05; // Between 5% and 15%
      expectedVolatility = Math.random() * 0.1 + 0.1; // Between 10% and 20%
    }
  
    const now = new Date();
    let startDate;
    let interval;
    let points;
    let pointsPerYear;
  
    switch (granularity) {
      case '10Y':
        startDate = new Date(now.getFullYear() - 10, now.getMonth(), now.getDate());
        interval = 'month';
        points = 121;
        pointsPerYear = 12;
        break;
      case '5Y':
        startDate = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate());
        interval = 'month';
        points = 61;
        pointsPerYear = 12;
        break;
      case '1Y':
        startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        interval = 'week';
        points = 53;
        pointsPerYear = 52;
        break;
      case '6M':
        startDate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
        interval = 'week';
        points = 27;
        pointsPerYear = 52;
        break;
      case '1M':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
        interval = 'day';
        points = 31;
        pointsPerYear = 365;
        break;
      case '1W':
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
        interval = 'hour';
        points = 169;
        pointsPerYear = 8760;
        break;
      case '1D':
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
        interval = 'hour';
        points = 25;
        pointsPerYear = 8760;
        break;
      default:
        startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        interval = 'week';
        points = 53;
        pointsPerYear = 52;
    }
  
    const data = [];
    let currentDate = new Date(startDate);
    let investmentValue = initialValue;
  
    for (let i = 0; i < points; i++) {
      const randomReturn =
        expectedReturn / pointsPerYear +
        (expectedVolatility / Math.sqrt(pointsPerYear)) * (Math.random() - 0.5);
      investmentValue *= Math.exp(randomReturn);
  
      data.push({
        x: new Date(currentDate),
        y: parseFloat(investmentValue.toFixed(2)),
      });
  
      switch (interval) {
        case 'month':
          currentDate.setMonth(currentDate.getMonth() + 1);
          break;
        case 'week':
          currentDate.setDate(currentDate.getDate() + 7);
          break;
        case 'day':
          currentDate.setDate(currentDate.getDate() + 1);
          break;
        case 'hour':
          currentDate.setHours(currentDate.getHours() + 1);
          break;
      }
    }
  
    return data;
  };
  
  export const getRandomStatistics = () => {
    return {
      returns: `${(Math.random() * 20 - 5).toFixed(2)}%`,
      volatility: `${(Math.random() * 15 + 5).toFixed(2)}%`,
      sharpe: (Math.random() * 2 + 0.5).toFixed(2),
    };
  };
  
  export const generateCorrelationMatrix = (labels) => {
    const matrix = [['-', ...labels]];
  
    for (let i = 0; i < labels.length; i++) {
      const row = [labels[i]];
      for (let j = 0; j < labels.length; j++) {
        if (i === j) {
          row.push(1);
        } else if (j < i) {
          row.push(matrix[j + 1][i + 1]); // Symmetric value
        } else {
          // Generate random correlation between -1 and 1
          row.push(parseFloat((Math.random() * 2 - 1).toFixed(2)));
        }
      }
      matrix.push(row);
    }
    return matrix;
  };
  
  
  export const granularityOptions = [
    { label: '10 Years', value: '10Y' },
    { label: '5 Years', value: '5Y' },
    { label: '1 Year', value: '1Y' },
    { label: '6 Months', value: '6M' },
    { label: '1 Month', value: '1M' },
    { label: '1 Week', value: '1W' },
    { label: '1 Day', value: '1D' },
  ];
  
export const getRandomPerformanceData = (etfs) => {
    const periods = ['1D', '1W', '1M', '6M', '1Y', '5Y'];
    return etfs.map((etf) => ({
      symbol: etf.symbol,
      performance: periods.reduce((acc, period) => {
        acc[period] = getRandomNumber(
          period === '1D'
            ? -2
            : period === '1W'
            ? -10
            : period === '1M'
            ? -20
            : period === '6M'
            ? -30
            : period === '1Y'
            ? -40
            : -50,
          period === '1D'
            ? 5
            : period === '1W'
            ? 15
            : period === '1M'
            ? 25
            : period === '6M'
            ? 35
            : period === '1Y'
            ? 45
            : 55,
          2
        );
        return acc;
      }, {}),
    }));
  };
  
const getRandomNumber = (min, max, decimals = 2) =>
    parseFloat((Math.random() * (max - min) + min).toFixed(decimals));
  
export  const createETFList = (etfSymbols) => {
    return etfSymbols.map((symbol) => ({
      symbol,
      description: `${symbol} ETF`,
      company: 'Various',
      totalAssets: `$${(Math.random() * 50 + 1).toFixed(2)}B`,
      price: `$${(Math.random() * 500 + 10).toFixed(2)}`,
      performance: { '1Y': getRandomNumber(-10, 20) },
    }));
  };
  
  const getRandomCorrelations = (etfSymbols) => {
    const correlations = [];
    const headers = ['-'].concat(etfSymbols);
    correlations.push(headers);
    for (let i = 0; i < etfSymbols.length; i++) {
      const row = [etfSymbols[i]];
      for (let j = 0; j < etfSymbols.length; j++) {
        if (i === j) {
          row.push(1);
        } else if (j < i) {
          row.push(correlations[j + 1][i + 1]);
        } else {
          const corr = parseFloat((Math.random() * 0.8 + 0.2).toFixed(2));
          row.push(corr);
        }
      }
      correlations.push(row);
    }
    return correlations;
  };
  
  const getTopPerformer = (performanceData) => {
    const oneYearPerformances = performanceData.map((etf) => ({
      symbol: etf.symbol,
      performance: etf.performance['1Y'],
    }));
    const topPerformer = oneYearPerformances.reduce((max, etf) =>
      etf.performance > max.performance ? etf : max
    );
    return {
      symbol: topPerformer.symbol,
      performance: `${topPerformer.performance}%`,
    };
  };
  
export const populateSubgroups = (
    parentGroup,
    etfListObject,
    categoryDescription
  ) => {
    for (const [subgroupName, etfSymbols] of Object.entries(etfListObject)) {
      const etfs = createETFList(etfSymbols);
      const performanceData = getRandomPerformanceData(etfs);
      const correlations = getRandomCorrelations(etfSymbols);
      const topPerformer = getTopPerformer(performanceData);
  
      parentGroup.subgroups[subgroupName] = {
        description: generateSubgroupDescription(
          subgroupName,
          etfs,
          categoryDescription
        ),
        etfs,
        statistics: getRandomStatistics(),
        performanceData,
        correlations,
        topPerformer,
      };
    }
  };
  
  const generateSubgroupDescription = (
    subgroupName,
    etfs,
    categoryDescription
  ) => {
    const etfSymbols = etfs.map((etf) => etf.symbol).join(', ');
    const readableName = subgroupName.replace(/_/g, ' ');
    return `${readableName
      .charAt(0)
      .toUpperCase()}${readableName.slice(
      1
    )} ETFs focus on ${categoryDescription} related to ${readableName.replace(
      /_/g,
      ' '
    )}. This group includes ETFs such as ${etfSymbols}, providing investors with exposure to this specific market segment.`;
  };
  
  export const aggregateGroupData = (group) => {
    const allPerformanceData = [];
    const allStatistics = [];
    Object.values(group.subgroups).forEach((subgroup) => {
      if (subgroup.performanceData) {
        allPerformanceData.push(...subgroup.performanceData);
      }
      if (subgroup.statistics) {
        allStatistics.push(subgroup.statistics);
      }
    });
    group.performanceData = allPerformanceData;
    group.statistics = getRandomStatistics();
  };

  // New Helper Functions for Additional Features

const getDatePoints = (granularity) => {
  const now = new Date();
  let startDate;
  let interval;
  let points;
  let pointsPerYear;

  switch (granularity) {
    case '10Y':
      startDate = new Date(now.getFullYear() - 10, now.getMonth(), now.getDate());
      interval = 'month';
      points = 121;
      pointsPerYear = 12;
      break;
    case '5Y':
      startDate = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate());
      interval = 'month';
      points = 61;
      pointsPerYear = 12;
      break;
    case '1Y':
      startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      interval = 'week';
      points = 53;
      pointsPerYear = 52;
      break;
    case '6M':
      startDate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      interval = 'week';
      points = 27;
      pointsPerYear = 52;
      break;
    case '1M':
      startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
      interval = 'day';
      points = 31;
      pointsPerYear = 365;
      break;
    case '1W':
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
      interval = 'hour';
      points = 169;
      pointsPerYear = 8760;
      break;
    case '1D':
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      interval = 'hour';
      points = 25;
      pointsPerYear = 8760;
      break;
    default:
      startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      interval = 'week';
      points = 53;
      pointsPerYear = 52;
  }

  return { startDate, interval, points, pointsPerYear };
};

const advanceDate = (currentDate, interval) => {
  switch (interval) {
    case 'month':
      currentDate.setMonth(currentDate.getMonth() + 1);
      break;
    case 'week':
      currentDate.setDate(currentDate.getDate() + 7);
      break;
    case 'day':
      currentDate.setDate(currentDate.getDate() + 1);
      break;
    case 'hour':
      currentDate.setHours(currentDate.getHours() + 1);
      break;
    default:
      currentDate.setDate(currentDate.getDate() + 1);
  }
};


// New functions for the additional graph options

export const generateRiskFactorReturns = (n_factors, granularity) => {
  const datasets = [];

  for (let i = 1; i <= n_factors; i++) {
    const label = `Factor ${i}`;
    const data = [];
    const { startDate, interval, points, pointsPerYear } = getDatePoints(granularity);
    let currentDate = new Date(startDate);
    let value = 100;

    for (let j = 0; j < points; j++) {
      const expectedReturn = 0.05;
      const expectedVolatility = 0.15;
      const randomReturn =
        expectedReturn / pointsPerYear +
        (expectedVolatility / Math.sqrt(pointsPerYear)) * (Math.random() - 0.5);
      value *= Math.exp(randomReturn);

      data.push({
        x: new Date(currentDate),
        y: parseFloat(value.toFixed(2)),
      });

      advanceDate(currentDate, interval);
    }

    datasets.push({ label, data });
  }

  return datasets;
};

export const generateAlphaFactorReturns = (n_factors, granularity) => {
  const datasets = [];

  for (let i = 1; i <= n_factors; i++) {
    const label = `Alpha Factor ${i}`;
    const data = [];
    const { startDate, interval, points, pointsPerYear } = getDatePoints(granularity);
    let currentDate = new Date(startDate);
    let value = 100;

    for (let j = 0; j < points; j++) {
      const expectedReturn = 0.07;
      const expectedVolatility = 0.2;
      const randomReturn =
        expectedReturn / pointsPerYear +
        (expectedVolatility / Math.sqrt(pointsPerYear)) * (Math.random() - 0.5);
      value *= Math.exp(randomReturn);

      data.push({
        x: new Date(currentDate),
        y: parseFloat(value.toFixed(2)),
      });

      advanceDate(currentDate, interval);
    }

    datasets.push({ label, data });
  }

  return datasets;
};

export const generateIdiosyncraticReturns = (asset, granularity) => {
  const data = [];
  const { startDate, interval, points } = getDatePoints(granularity);
  let currentDate = new Date(startDate);

  for (let i = 0; i < points; i++) {
    const randomReturn = (Math.random() - 0.5) * 0.02;
    data.push({
      x: new Date(currentDate),
      y: parseFloat(randomReturn.toFixed(4)),
    });

    advanceDate(currentDate, interval);
  }

  return data;
};

export const generateRhoPortfolioWeights = (granularity) => {
  const data = [];
  const { startDate, interval, points } = getDatePoints(granularity);
  let currentDate = new Date(startDate);

  for (let i = 0; i < points; i++) {
    const weight = Math.random();
    data.push({
      x: new Date(currentDate),
      y: parseFloat(weight.toFixed(4)),
    });

    advanceDate(currentDate, interval);
  }

  return data;
};

export const generatePortfolioWeights = (assets, granularity) => {
  const datasets = assets.map((asset) => ({ label: asset, data: [] }));
  const { startDate, interval, points } = getDatePoints(granularity);
  let currentDate = new Date(startDate);

  for (let i = 0; i < points; i++) {
    const randomWeights = assets.map(() => Math.random());
    const total = randomWeights.reduce((sum, w) => sum + w, 0);
    const weights = randomWeights.map((w) => w / total);

    for (let j = 0; j < assets.length; j++) {
      datasets[j].data.push({
        x: new Date(currentDate),
        y: parseFloat(weights[j].toFixed(4)),
      });
    }

    advanceDate(currentDate, interval);
  }

  return datasets;
};

export default {
  generatePortfolioData,
  generateInvestmentData,
  getRandomStatistics,
  generateCorrelationMatrix,
  granularityOptions,
  getRandomPerformanceData,
  createETFList,
  populateSubgroups,
  aggregateGroupData,
  // Export new functions
  generateRiskFactorReturns,
  generateAlphaFactorReturns,
  generateIdiosyncraticReturns,
  generateRhoPortfolioWeights,
  generatePortfolioWeights
}
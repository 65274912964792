// Philosophy.js

import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import InternalTabBar from '../../components/tabs/InternalTabBar';
import HowItWorks from './HowItWorks';
import Alpha from './Alpha';
import Beta from './Beta';
import Rho from './Rho';
import TaxEfficiencies from './TaxEfficiencies';

const Philosophy = () => {
  const [selectedTab, setSelectedTab] = useState('HowItWorks');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 'HowItWorks':
        return <HowItWorks />;
      case 'Alpha':
        return <Alpha />;
      case 'Beta':
        return <Beta />;
      case 'Rho':
        return <Rho />;
      case 'Taxes':
        return <TaxEfficiencies />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        paddingTop: '50px',
        backgroundColor: '#f0f8f6',
        minHeight: '100vh',
      }}
    >
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          marginLeft: '11%', // Account for the tab bar
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
        }}
      >
        {/* Tabs */}
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            marginBottom: 4,
            '& .MuiTabs-flexContainer': {
              justifyContent: 'center',
            },
          }}
        >
          <Tab value="HowItWorks" label="How It Works"/>
          <Tab value="Alpha" label="Alpha" />
          <Tab value="Beta" label="Beta" />
          <Tab value="Rho" label="Rho" />
          <Tab value="Taxes" label="Taxes" />
        </Tabs>

        {/* Rendered Content */}
        {renderContent()}
      </Box>
    </Box>
  );
};

export default Philosophy;

import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar';
import { API_BASE_URL } from '../../constants';

const Plots = () => {
  const [plots, setPlots] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchPlots = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/asset-return-plots/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to load the plots');
        }

        const data = await response.json(); // Assuming JSON with multiple plots
        setPlots(data.plots); // Plots stored in state
      } catch (error) {
        console.error('Error fetching plots:', error);
        setErrorMessage('Error loading plots. Please try again later.');
      }
    };

    fetchPlots();
  }, []);

  // Function to execute embedded scripts after rendering the HTML
  const executePlotlyScripts = () => {
    const scriptElements = document.querySelectorAll("script");
    scriptElements.forEach((script) => {
      const scriptTag = document.createElement("script");
      scriptTag.text = script.text;
      document.body.appendChild(scriptTag);
      document.body.removeChild(scriptTag); // Clean up after execution
    });
  };

  useEffect(() => {
    if (Object.keys(plots).length > 0) {
      executePlotlyScripts(); // Execute scripts when plots are loaded
    }
  }, [plots]);

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#f0f8f6' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '89%',
          margin: '0 auto',
          marginLeft: '11%',
          flexGrow: 1,
          marginTop: '85px',
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            marginTop: '65px',
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Plotly Visualizations
        </Typography>

        {/* Description */}
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
          }}
        >
          Here are the Plotly charts fetched from the backend.
        </Typography>

        {/* Plot(s) */}
        {errorMessage ? (
          <Typography level="body2" color="danger">
            {errorMessage}
          </Typography>
        ) : (
          <Box sx={{ marginBottom: 4 }}>
            {Object.keys(plots).length > 0 ? (
              Object.keys(plots).map((plotName, index) => (
                <Box key={index} sx={{ marginBottom: 4 }}>
                  <Typography
                    sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#000000' }}
                  >
                    {plotName}
                  </Typography>
                  <div
                    dangerouslySetInnerHTML={{ __html: plots[plotName] }} // Rendering the HTML content of each plot
                  />
                </Box>
              ))
            ) : (
              <Typography>No plots available for display.</Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Plots;

import React from 'react';
import { Box, Typography, Grid, Sheet } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar';
import PropTypes from 'prop-types';

const HowItWorks = () => {
  return (
    <Box sx={{ display: 'flex', backgroundColor: '#f0f8f6', minHeight: '100vh' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          How It Works
        </Typography>
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
          }}
        >
          A breakdown of our investment strategy blending Alpha, Beta, and Rho.
        </Typography>

        {/* Introductory Explanation */}
        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4, textAlign: 'center' }}
        >
          At AlgoInvest, we blend traditional investment wisdom with advanced technology to
          build a diversified portfolio tailored to your financial goals. Our Core Fund gives
          you exposure to various asset classes—equities, fixed income, commodities, and real
          estate—through themes and companies you know & trust.
        </Typography>

        {/* Bringing It All Together Section */}
        <Box sx={{ marginBottom: 4, textAlign: 'left' }}>
          <Typography
            sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 3 }}
          >
            Bringing It All Together
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            By integrating Alpha, Beta, and Rho, we construct a balanced and dynamic portfolio:
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Alpha:</strong> Enhanced returns via active management.</li>
              <li><strong>Beta:</strong> Diversified market exposure across various asset classes.</li>
              <li><strong>Rho:</strong> Provides stability through risk-free returns.</li>
            </ul>
            This comprehensive approach is designed to align with your financial objectives, manage
            risk effectively, and capitalize on market opportunities.
          </Typography>
        </Box>

        {/* How It Works Sections */}
        <Box>
          <HowItWorksSections />
        </Box>
      </Box>
    </Box>
  );
};

export default HowItWorks;

// HowItWorksSections Component
const HowItWorksSections = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    {/* Grid of How It Works Cards */}
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <HowItWorksCard title="Alpha: Seeking Outperformance at Every Level">
          <Typography sx={{ color: '#333' }}>
            <strong>Alpha</strong> represents our active strategies aimed at outperforming
            the market. We apply alpha strategies at each layer of our hierarchical asset
            tree, targeting opportunities for enhanced returns.
          </Typography>
        </HowItWorksCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <HowItWorksCard title="Dynamic Allocation for Alpha and Beta">
          <Typography sx={{ color: '#333' }}>
            We dynamically adjust allocations between beta and alpha views,
            optimizing for market conditions and performance opportunities.
          </Typography>
        </HowItWorksCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <HowItWorksCard title="Beta: Capturing Market Exposure Across Asset Classes">
          <Typography sx={{ color: '#333' }}>
            <strong>Beta</strong> forms the core of your portfolio's market exposure. Our Beta
            encompasses a broader range of asset classes:
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Equities:</strong> Stocks from various sectors and regions.</li>
              <li><strong>Fixed Income:</strong> Government and corporate bonds.</li>
              <li><strong>Commodities:</strong> Gold, oil, agricultural products.</li>
              <li><strong>Real Estate:</strong> Property investments.</li>
            </ul>
          </Typography>
        </HowItWorksCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <HowItWorksCard title="Breaking Betas into Sub-Betas">
          <Typography sx={{ color: '#333' }}>
            Our Beta strategy adjusts between sub-betas dynamically:
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Global:</strong> Exposure to global equities.</li>
              <li><strong>Developed:</strong> Focus on North America and Europe.</li>
              <li><strong>Emerging:</strong> High growth in emerging markets.</li>
              <li><strong>Property Type:</strong> Residential, commercial, and industrial investments.</li>
            </ul>
          </Typography>
        </HowItWorksCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <HowItWorksCard title="Rho: The Foundation of Stability">
          <Typography sx={{ color: '#333' }}>
            <strong>Rho</strong> represents the portion of your portfolio allocated to
            risk-free investments, specifically high-yield savings accounts. We determine
            the ideal amount to allocate here, ensuring a stable foundation for your portfolio.
          </Typography>
        </HowItWorksCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <HowItWorksCard title="Why High-Yield Savings Accounts?">
          <ul style={{ color: '#333', marginLeft: '1.5rem' }}>
            <li><strong>Safety:</strong> Your principal is protected.</li>
            <li><strong>Liquidity:</strong> Easy access to your funds.</li>
            <li><strong>Steady Returns:</strong> Earn competitive interest.</li>
          </ul>
        </HowItWorksCard>
      </Grid>
    </Grid>
  </Box>
);

// HowItWorksCard Component
const HowItWorksCard = ({ title, children }) => (
  <Box sx={{ padding: '1.5rem', display: 'flex', flexDirection: 'column' }}>
    <Typography
      sx={{
        fontSize: '1.25rem',
        fontWeight: '600',
        color: '#40826D',
        marginBottom: '1rem',
      }}
    >
      {title}
    </Typography>
    <Typography sx={{ flexGrow: 1, fontSize: '1rem', lineHeight: 1.8, color: '#333' }}>
      {children}
    </Typography>
  </Box>
);

HowItWorksCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
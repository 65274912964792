import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Grid, Alert, Sheet, Typography, FormControl, Select, Option } from '@mui/joy';
import { Link as RouterLink } from 'react-router-dom';
import ProfileHeader from './ProfileHeader';
import PortfolioCard from './PortfolioCard';
import RankedPerformanceETFs from './RankedPerformanceETFs';
import InternalTabBar from '../../components/tabs/InternalTabBar';
import { fetchUserData } from '../../utils/api';
import dummyUserData from '../../data/userData'; // Import the dummy data
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { equitiesData } from '../../data/assetClassData';
import PerformanceChart from '../../components/charts/PerformanceChart';
import { generatePortfolioData, granularityOptions } from '../../data/assetClassDataGenerators';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [portfolioData, setPortfolioData] = useState([]);
  const [granularity, setGranularity] = useState('10Y');

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const data = await fetchUserData();
        if (data) {
          setUserData(data);
        } else {
          throw new Error("User data not found");
        }
      } catch (error) {
        console.error(error);
        toast.error('Failed to load user data. Using dummy data.');
        setUserData(dummyUserData);
      } finally {
        setLoading(false);
      }
    };
    loadUserData();
  }, []);

  useEffect(() => {
    if (userData?.accounts?.length > 0) {
      const aggregatedData = userData.accounts.map((account) => ({
        label: account.name,
        data: generatePortfolioData(granularity, account.balance),
      }));
      setPortfolioData(aggregatedData);
    }
  }, [userData, granularity]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundColor: '#f0f8f6', // Updated background color
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', paddingTop: '50px', backgroundColor: '#f0f8f6', minHeight: '100vh' }}> {/* Updated background color */}
      <InternalTabBar />

      {/* Main content */}
      <Box sx={{ flexGrow: 1, marginLeft: { xs: 0, md: '11%' }, p: { xs: 2, md: 4 } }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={10}>
            <ProfileHeader
              userName={`${userData.firstname} ${userData.lastname}`}
              selectedAccount={selectedAccount}
              accounts={userData.accounts}
              onAccountChange={(accountId) =>
                setSelectedAccount(userData.accounts.find((acc) => acc.id === accountId))
              }
            />
            {userData.accounts?.length > 0 ? (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '20px' }}>
                  <FormControl sx={{ minWidth: 250 }} size="lg">
                    <Select
                      placeholder="Select Time Period"
                      value={granularity}
                      onChange={(e, newValue) => setGranularity(newValue)}
                      sx={{
                        fontSize: '1.15rem',
                        color: '#333',
                        backgroundColor: '#ffffff',
                        padding: '8px 20px',
                        fontWeight: '500',
                        borderRadius: '30px',
                        '&:hover': {
                          backgroundColor: '#e6f7f2',
                        },
                      }}
                    >
                      {granularityOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ height: { xs: 400, md: 600 }, marginTop: 4, marginBottom: 4 }}>
                  <PerformanceChart
                    data={portfolioData}
                    granularity={granularity}
                    yAxisLabel="Balance (USD)"
                    tooltipFormat={(value) => `$${value.toFixed(2)}`}
                  />
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Sheet sx={{ p: 3, bgcolor: '#f0f8f6', borderRadius: 'none', boxShadow: 'none', border: 'none', height: '100%' }}> {/* Updated background color */}
                      <RankedPerformanceETFs type="top" etfData={equitiesData.groups} />
                    </Sheet>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Sheet sx={{ p: 3, bgcolor: '#f0f8f6', borderRadius: 'none', boxShadow: 'none', border: 'none', height: '100%' }}> {/* Updated background color */}
                      <RankedPerformanceETFs type="bottom" etfData={equitiesData.groups} />
                    </Sheet>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Alert sx={{ textAlign: 'center', mt: 4, backgroundColor: '#f0f8f6', color: '#2f3b52' }} variant="soft" color="info">
                You currently have no investment accounts. Please add an account in your settings.
              </Alert>
            )}
          </Grid>
        </Grid>
        <ToastContainer />
      </Box>
    </Box>
  );
};

export default Profile;

// import React, { useState, useEffect } from 'react';
// import { Box, Button, CircularProgress, Grid, Alert, Sheet, Typography, FormControl, Select, Option } from '@mui/joy';
// import { Link as RouterLink } from 'react-router-dom';
// import ProfileHeader from './ProfileHeader';
// import PortfolioCard from './PortfolioCard';
// import RankedPerformanceETFs from './RankedPerformanceETFs';
// import InternalTabBar from '../../components/tabs/InternalTabBar';
// import { fetchUserData } from '../../utils/api';
// import dummyUserData from '../../data/userData'; // Import the dummy data
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { equitiesData } from '../../data/assetClassData';
// import PerformanceChart from '../../components/charts/PerformanceChart';
// import { generatePortfolioData, granularityOptions } from '../../data/assetClassDataGenerators';

// const Profile = () => {
//   const [userData, setUserData] = useState(null);
//   const [selectedAccount, setSelectedAccount] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [portfolioData, setPortfolioData] = useState([]);
//   const [granularity, setGranularity] = useState('10Y');

//   useEffect(() => {
//     const loadUserData = async () => {
//       try {
//         const data = await fetchUserData();
//         if (data) {
//           setUserData(data);
//         } else {
//           throw new Error("User data not found");
//         }
//       } catch (error) {
//         console.error(error);
//         toast.error('Failed to load user data. Using dummy data.');
//         setUserData(dummyUserData);
//       } finally {
//         setLoading(false);
//       }
//     };
//     loadUserData();
//   }, []);

//   useEffect(() => {
//     if (userData?.accounts?.length > 0) {
//       const aggregatedData = userData.accounts.map((account) => ({
//         label: account.name,
//         data: generatePortfolioData(granularity, account.balance),
//       }));
//       setPortfolioData(aggregatedData);
//     }
//   }, [userData, granularity]);

//   if (loading) {
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           minHeight: '100vh',
//           backgroundColor: '#f9f9f9',
//         }}
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Box sx={{ display: 'flex', paddingTop: '50px', backgroundColor: '#fff', minHeight: '100vh' }}>
//       <InternalTabBar />

//       {/* Main content */}
//       <Box sx={{ flexGrow: 1, marginLeft: { xs: 0, md: '11%' }, p: { xs: 2, md: 4 } }}>
//         <Grid container spacing={4} justifyContent="center">
//           <Grid item xs={12} md={10}>
//             <ProfileHeader
//               userName={`${userData.firstname} ${userData.lastname}`}
//               selectedAccount={selectedAccount}
//               accounts={userData.accounts}
//               onAccountChange={(accountId) =>
//                 setSelectedAccount(userData.accounts.find((acc) => acc.id === accountId))
//               }
//             />
//             {userData.accounts?.length > 0 ? (
//               <>
//                 <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '20px' }}>
//                   <FormControl sx={{ minWidth: 250 }} size="lg">
//                     <Select
//                       placeholder="Select Time Period"
//                       value={granularity}
//                       onChange={(e, newValue) => setGranularity(newValue)}
//                       sx={{
//                         fontSize: '1.15rem',
//                         color: '#333',
//                         backgroundColor: '#ffffff',
//                         padding: '8px 20px',
//                         fontWeight: '500',
//                         borderRadius: '30px',
//                         '&:hover': {
//                           backgroundColor: '#e6f7f2',
//                         },
//                       }}
//                     >
//                       {granularityOptions.map((option) => (
//                         <Option key={option.value} value={option.value}>
//                           {option.label}
//                         </Option>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Box>
//                 <Box sx={{ height: { xs: 400, md: 600 }, marginTop: 4, marginBottom: 4 }}>
//                   <PerformanceChart
//                     data={portfolioData}
//                     granularity={granularity}
//                     yAxisLabel="Balance (USD)"
//                     tooltipFormat={(value) => `$${value.toFixed(2)}`}
//                   />
//                 </Box>
//                 <Grid container spacing={3}>
//                   <Grid item xs={12} md={6}>
//                     <Sheet sx={{ p: 3, bgcolor: '#fff', borderRadius: 'none', boxShadow: 'none', border: 'none', height: '100%' }}>
//                       <RankedPerformanceETFs type="top" etfData={equitiesData.groups} />
//                     </Sheet>
//                   </Grid>
//                   <Grid item xs={12} md={6}>
//                     <Sheet sx={{ p: 3, bgcolor: '#fff', borderRadius: 'none', boxShadow: 'none', border: 'none', height: '100%' }}>
//                       <RankedPerformanceETFs type="bottom" etfData={equitiesData.groups} />
//                     </Sheet>
//                   </Grid>
//                 </Grid>
//               </>
//             ) : (
//               <Alert sx={{ textAlign: 'center', mt: 4 }} variant="soft" color="info">
//                 You currently have no investment accounts. Please add an account in your settings.
//               </Alert>
//             )}
//           </Grid>
//         </Grid>
//         <ToastContainer />
//       </Box>
//     </Box>
//   );
// };

// export default Profile;
import React from 'react';
import { Box, Typography, Grid } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar';
import PropTypes from 'prop-types';

const Rho = () => {
  return (
    <Box sx={{ display: 'flex', backgroundColor: '#f0f8f6', minHeight: '100vh' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Rho: Harnessing the Power of the Risk-Free Rate
        </Typography>
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
            textAlign: 'center', 
          }}
        >
          Discover how we integrate high-yield savings accounts into your portfolio to provide stability and steady returns.
        </Typography>
        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4 }}
        >
          In the investment world, the risk-free rate represents the return on an investment with zero risk, typically associated with government bonds or high-yield savings accounts. At AlgoInvest, we recognize the importance of incorporating this stability into your portfolio.
        </Typography>

        {/* Explanation Section */}
        <Box sx={{ marginBottom: 4, textAlign: 'left' }}>
          <Typography
            sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 3 }}
          >
            How We Use Rho
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            At AlgoInvest, Rho represents the risk-free rate, which we implement by allocating to high-yield savings accounts. This allocation ensures that a portion of your portfolio remains safe from market volatility, providing stability and consistent returns.
          </Typography>
          <Typography
            sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginTop: 2 }}
          >
            We analyze your financial goals and risk tolerance to determine the ideal portion of your portfolio allocated to Rho, optimizing for both safety and growth.
          </Typography>
        </Box>

        {/* Rho Sections */}
        <Box>
          <RhoSections />
        </Box>
      </Box>
    </Box>
  );
};

export default Rho;

// RhoSections Component
const RhoSections = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    {/* Grid of Rho Cards */}
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <RhoCard title="Embracing the Risk-Free Rate">
          <Typography sx={{ color: '#333' }}>
            Rho represents the portion of your investment allocated to high-yield savings accounts. These accounts offer reliable, predictable returns without exposure to market fluctuations.
          </Typography>
        </RhoCard>
      </Grid>
      <Grid item xs={12} md={4}>
        <RhoCard title="Why High-Yield Savings Accounts?">
          <Typography sx={{ color: '#333' }}>
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Safety:</strong> Your principal is protected.</li>
              <li><strong>Liquidity:</strong> Easy access to your funds.</li>
              <li><strong>Steady Returns:</strong> Earn competitive interest.</li>
            </ul>
          </Typography>
        </RhoCard>
      </Grid>
      <Grid item xs={12} md={4}>
        <RhoCard title="Determining the Ideal Allocation">
          <Typography sx={{ color: '#333' }}>
            We analyze market conditions to determine how much of your portfolio to allocate to Rho. This ensures that the risk-free component is properly utilized to stabilize our beta and alpha approaches.
          </Typography>
        </RhoCard>
      </Grid>
    </Grid>
  </Box>
);

// RhoCard Component
const RhoCard = ({ title, children }) => (
  <Box sx={{ padding: '1.5rem', display: 'flex', flexDirection: 'column' }}>
    <Typography
      sx={{
        fontSize: '1.25rem',
        fontWeight: '600',
        color: '#40826D',
        marginBottom: '1rem',
      }}
    >
      {title}
    </Typography>
    <Typography sx={{ flexGrow: 1, fontSize: '1rem', lineHeight: 1.8, color: '#333' }}>
      {children}
    </Typography>
  </Box>
);

RhoCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
// App.js
import './App.css';

import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './routes/legal/PrivacyPolicy';

import HomePage from "./routes/home/HomePage";
import Login from "./routes/login/Login";
import Signup from "./routes/signup/SignUp";
import Settings from "./routes/settings/Settings";
import Profile from "./routes/profile/Profile";
// import Equities from "./routes/internal/Equities";
// import FixedIncome from "./routes/internal/FixedIncome";
// import Commodities from "./routes/internal/Commodities";
// import RealEstate from "./routes/internal/RealEstate";
// import CoreFundPerformance from './routes/internal/CoreFundPerformance';
import HowItWorks from './routes/internal/HowItWorks';
import Beta from "./routes/internal/Beta";
import TaxEfficiencies from "./routes/internal/TaxEfficiencies";
import NotFound from "./routes/error/NotFound";
import TermsAndConditions from './routes/legal/TermsAndConditions';
import ApplicationSubmitted from './routes/application/ApplicationSubmitted';
import PendingVerification from './routes/application/PendingVerification';
import ForgotPassword from './routes/login/ForgotPassword';
import ResetPassword from './routes/login/ResetPassword';
import Performance from './routes/internal/Performance';

import SEOWrapper from "./components/SEOWrapper";
import PrivateRoute from "./routes/routing/PrivateRoute";
import PublicRoute from "./routes/routing/PublicRoute";

import { CssVarsProvider } from '@mui/joy/styles';
import theme from './theme';
import Rho from './routes/internal/Rho';
import Alpha from './routes/internal/Alpha';
import { Public } from '@mui/icons-material';
import Philosophy from './routes/internal/Philosophy';
import Portfolio from './routes/internal/Portfolio';
import Plots from './routes/internal/Plots';

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      // Public Routes
      {
        path: "",
        element: (
          <PublicRoute
            element={
              <SEOWrapper
                title="Home - AlgoInvest"
                description="AlgoInvest offers AI-powered investment tools and portfolio management."
              >
                <HomePage />
              </SEOWrapper>
            }
          />
        ),
      },
      {
        path: "login",
        element: (
          <PublicRoute
            element={
              <SEOWrapper
                title="Login - AlgoInvest"
                description="Access your AlgoInvest account"
              >
                <Login />
              </SEOWrapper>
            }
          />
        ),
      },
      {
        path: "signup",
        element: (
          <PublicRoute
            element={
              <SEOWrapper
                title="Sign Up - AlgoInvest"
                description="Create your AlgoInvest account"
              >
                <Signup />
              </SEOWrapper>
            }
          />
        ),
      },
      {
        path: "terms-and-conditions",
        element: (
          <SEOWrapper
            title="Terms and Conditions - AlgoInvest"
            description="Read the terms and conditions for using AlgoInvest."
          >
            <TermsAndConditions />
          </SEOWrapper>
        ),
      },
      {
        path: "application-submitted",
        element: (
          <PublicRoute
            element={
              <SEOWrapper
                title="Application Submitted - AlgoInvest"
                description="Your application has been submitted."
              >
                <ApplicationSubmitted />
              </SEOWrapper>
            }
          />
        ),
      },
      {
        path: "pending-verification",
        element: (
          <SEOWrapper
            title="Pending Verification - AlgoInvest"
            description="Your account is pending verification."
          >
            <PendingVerification />
          </SEOWrapper>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <PublicRoute
            element={
              <SEOWrapper
                title="Forgot Password - AlgoInvest"
                description="Reset your AlgoInvest account password."
              >
                <ForgotPassword />
              </SEOWrapper>
            }
          />
        ),
      },
      {
        path: "reset-password/:token",
        element: (
          <PublicRoute
            element={
              <SEOWrapper
                title="Reset Password - AlgoInvest"
                description="Set a new password for your AlgoInvest account."
              >
                <ResetPassword />
              </SEOWrapper>
            }
          />
        ),
      },
      {
        path: "privacy-policy",
        element: (
          <PublicRoute
            element={
              <SEOWrapper
                title="Privacy Policy - AlgoInvest"
                description="How we keep you safe"
              >
                <PrivacyPolicy />
              </SEOWrapper>
            }
          />
        ),
      },
      // Private Routes
      {
        path: "settings",
        element: (
          <PrivateRoute
            element={
              <SEOWrapper
                title="Settings - AlgoInvest"
                description="Adjust your AlgoInvest account settings"
              >
                <Settings />
              </SEOWrapper>
            }
          />
        ),
      },
      {
        path: "profile",
        element: (
          <PrivateRoute
            element={
              <SEOWrapper
                title="Dashboard - AlgoInvest"
                description="Your AlgoInvest investment dashboard"
              >
                <Profile />
              </SEOWrapper>
            }
          />
        ),
      },
      {
        path: "portfolio",
        element: (
          <PrivateRoute
            element={
              <SEOWrapper
                title="Portfolio - AlgoInvest"
                description="Visualize your portfolio"
              >
                <Portfolio />
              </SEOWrapper>
            }
          />
        ),
      },
      {
        path: "philosophy",
        element: (
          <PrivateRoute
            element={
              <SEOWrapper
                title="Philosophy - AlgoInvest"
                description="Oversee your total diversified investment fund"
              >
                <Philosophy />
              </SEOWrapper>
            }
          />
        ),
      },
      {
        path: "performance",
        element: (
          <PrivateRoute
            element={
              <SEOWrapper
                title="Performance - AlgoInvest"
                description="Oversee your total diversified investment fund"
              >
                <Performance />
              </SEOWrapper>
            }
          />
        ),
      },
      {
        path: "plots",
        element: (
          <PrivateRoute
            element={
              <SEOWrapper
                title="Plots - AlgoInvest"
                description="View Live Metrics"
              >
                <Plots />
              </SEOWrapper>
            }
          />
        ),
      },
      // Catch-all Route
      {
        path: "*",
        element: (
          <SEOWrapper
            title="Page Not Found - AlgoInvest"
            description="The page you're looking for doesn't exist. Navigate back to AlgoInvest's homepage."
          >
            <NotFound />
          </SEOWrapper>
        ),
      },
    ],
  },
]);


function App() {
  return (
     <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

import React from 'react';
import { Box, Typography, Grid } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar';
import PropTypes from 'prop-types';

const Alpha = () => {
  return (
    <Box sx={{ display: 'flex', backgroundColor: '#f0f8f6', minHeight: '100vh' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: '100%',
          margin: '0 auto',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        {/* Page Title */}
        <Typography
          sx={{
            marginBottom: 2,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#40826D',
          }}
        >
          Alpha: Active Insights Across All Asset Classes
        </Typography>
        <Typography
          sx={{
            marginBottom: 4,
            fontSize: '1.5rem',
            color: '#478778',
            textAlign: 'center',
          }}
        >
          Discover how we apply our alpha strategies at every level of your portfolio to seek enhanced returns.
        </Typography>

        {/* Introductory Explanation */}
        <Typography
          sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333', marginBottom: 4, textAlign: 'center' }}
        >
          Our Alpha strategies are not confined to a single asset class or level. We apply an overall alpha view across all asset classes, composed of sub-alpha views at each layer of our hierarchical asset tree. This ensures we pursue outperformance at every level, from broad asset classes to individual investments.
        </Typography>

        {/* Explanation Section */}
        <Box sx={{ marginBottom: 4, textAlign: 'left' }}>
          <Typography
            sx={{ fontSize: '2rem', fontWeight: '600', color: '#40826D', marginBottom: 3 }}
          >
            How We Use Alpha
          </Typography>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8, color: '#333' }}>
            Our Alpha strategy aims to seek opportunities for enhanced returns by applying advanced analysis, quantitative models, and machine learning. We dynamically allocate across all asset classes to take advantage of market inefficiencies, undervalued assets, and growth opportunities.
          </Typography>
        </Box>

        {/* Alpha Sections */}
        <Box>
          <AlphaSections />
        </Box>
      </Box>
    </Box>
  );
};

export default Alpha;

// AlphaSections Component
const AlphaSections = () => (
  <Box sx={{ padding: { xs: 4, md: 8 }, textAlign: 'left' }}>
    {/* Grid of Alpha Cards */}
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <AlphaCard title="Applying Alpha Across the Beta Tree">
          <Typography sx={{ color: '#333' }}>
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Equities:</strong> Focus on emerging or undervalued stocks.</li>
              <li><strong>Fixed Income:</strong> High-yield corporate or emerging market debt.</li>
              <li><strong>Commodities:</strong> Exploit supply-demand imbalances in resources.</li>
              <li><strong>Real Estate:</strong> Invest in high-growth regions and sectors.</li>
            </ul>
          </Typography>
        </AlphaCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <AlphaCard title="The Hierarchical Alpha View">
          <Typography sx={{ color: '#333' }}>
            <ul style={{ marginLeft: '1.5rem' }}>
              <li>Outperform passive approach (pure beta).</li>
              <li>Analyze overarching asset-class views</li>
              <li>Break down into asset-theme views</li>
              <li>Conduct further analysis on the asset level</li>
              <li>Tailored strategies to each segment</li>
            </ul>
          </Typography>
        </AlphaCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <AlphaCard title="Utilizing Advanced Analytics">
          <Typography sx={{ color: '#333' }}>
            <ul style={{ marginLeft: '1.5rem' }}>
              <li><strong>Quantitative Models:</strong> Identify market patterns and trends.</li>
              <li><strong>Machine Learning:</strong> Generate predictive signals for market movements.</li>
              <li><strong>Fundamental Analysis:</strong> Evaluate economic indicators and financial data.</li>
            </ul>
            By combining these approaches, we aim to make well-informed investment decisions that enhance your portfolio's performance.
          </Typography>
        </AlphaCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <AlphaCard title="Dynamic Allocation for Alpha">
          <Typography sx={{ color: '#333' }}>
            We analyze which of our "alphas" are performing consistently well and focus our alpha strategies on those. Additionally, we constantly evaluate our beta strategies to inform how we incorporate our alpha strategies.
            <br /><br />
            This allows your portfolio to stay flexible, respond to market dynamics, and seize opportunities in real time, whether through equities, bonds, or alternative investments.
          </Typography>
        </AlphaCard>
      </Grid>
    </Grid>
  </Box>
);

// AlphaCard Component
const AlphaCard = ({ title, children }) => (
  <Box sx={{ padding: '1.5rem', display: 'flex', flexDirection: 'column' }}>
    <Typography
      sx={{
        fontSize: '1.25rem',
        fontWeight: '600',
        color: '#40826D',
        marginBottom: '1rem',
      }}
    >
      {title}
    </Typography>
    <Typography sx={{ flexGrow: 1, fontSize: '1rem', lineHeight: 1.8, color: '#333' }}>
      {children}
    </Typography>
  </Box>
);

AlphaCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Input, Button, Link, Alert } from '@mui/joy';
import TabBar from '../../components/tabs/TabBar';
import { API_BASE_URL } from '../../constants';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (!email) {
      setError('Please enter your email.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/forgot-password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage('If an account with that email exists, we have sent password reset instructions.');
      } else {
        throw new Error('Failed to send password reset email.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to send password reset email. Please try again.');
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#f0f8f6', display: 'flex', flexDirection: 'column' }}>
      <TabBar />

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: { xs: '2rem', md: '4rem' },
          paddingBottom: '2rem',
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            padding: { xs: '2rem 1.5rem', md: '3rem 2.5rem' },
            maxWidth: 400,
            width: '100%',
            backgroundColor: 'inherit', 
            textAlign: 'center',
          }}
          noValidate
        >
          <Typography level="h4" align="center" sx={{ color: '#40826D', fontSize: '2.4rem', marginBottom: '2rem', fontWeight: 'bold' }}>
            Forgot Password
          </Typography>

          <Box sx={{ position: 'relative', marginBottom: '1.5rem' }}>
            <Input
              name="email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={Boolean(error) && !email}
              required
              sx={{
                width: '100%',
                height: '3.5rem',
                padding: '1rem',
                fontSize: '1.3rem',
                borderRadius: '25px',
                borderColor: Boolean(error) && !email ? 'danger.600' : '#ccc',
                '&:focus': { borderColor: 'primary.600' },
              }}
            />
            {Boolean(error) && !email && (
              <Typography level="body3" color="danger.600" sx={{ mt: 0.5 }}>
                Email is required.
              </Typography>
            )}
          </Box>

          {message && (
            <Alert color="success" variant="soft" sx={{ marginBottom: '1rem' }}>
              {message}
            </Alert>
          )}

          {error && (
            <Alert color="danger" variant="soft" sx={{ marginBottom: '1rem' }}>
              {error}
            </Alert>
          )}

          <Button
            type="submit"
            variant="solid"
            color="success"
            fullWidth
            sx={{
              padding: '0.75rem',
              fontSize: '1.4rem',
              fontWeight: 'bold',
              backgroundColor: '#40826D',
              borderRadius: '30px',
              '&:hover': { backgroundColor: '#478778' },
            }}
          >
            Send Reset Instructions
          </Button>

          <Typography level="body2" align="center" sx={{ marginTop: '1.5rem', fontSize: '0.875rem' }}>
            Remembered your password?{' '}
            <Link component={RouterLink} to="/login" variant="body2" color="primary">
              Login
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;